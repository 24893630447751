/* revisar */
body {
	font-size  : 13px;
	font-family: 'Open Sans', sans-serif;
	color      : #424242;
	margin     : 0;
	cursor     : default;
	background : #FFF;
}

img {
	max-width: 100%;
}

a {
	text-decoration   : none;
	outline           : 0;
	color             : #008D8A;
	-webkit-transition: all 0.15s linear;
	-moz-transition   : all 0.15s linear;
	-o-transition     : all 0.15s linear;
	transition        : all 0.15s linear;
}

a:hover {
	color          : #303030;
	text-decoration: none;
}

a,
a:focus {
	outline        : 0;
	outline-offset : 0;
	text-decoration: none;
}

.navbar {
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

/* SECCIONES */
.page-content {
	padding   : 120px 0 0 0;
	position  : relative;
	overflow  : hidden;
	background: #FFF;
}

.page-content.last {
	padding: 120px 0 0 0;
}

.mdlnew .modal-content {
    border-radius: 1.3rem;
}

.infotel {
    margin: 30px;
    text-align: center;
}

.info-modal {
    margin: 30px;

	font-family: OpenSans;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.63;
	letter-spacing: normal;
	text-align: left;
	color: #424242;
}

/* Para las secciones con fondo de color */
.green {
	background-color: #DDDDDD;
}

.main-heading {
	padding    : 5px 0 0 25px;
	border-left: 2px solid #59585b;
	position   : relative;
	margin     : 0 0 60px 0;
}

.main-heading h4 {
	margin        : 0;
	font-size     : 18px;
	font-weight   : 500;
	color         : #008D8A;
	text-transform: uppercase;
}

.main-heading h1 {
	margin-top : 0;
	font-size  : 40px;
	font-weight: 700;
	color      : #59585b;
}

.intro-section {
	min-height      : 100vh;
	background-color: #dddddd;
	overflow        : hidden;
	padding-bottom  : 60px;
}

/* ELEMENTOS BASE */
.btn-primary {
	background-color: #008e8b;
	border-color    : #008e8b;
}

.btn-primary:hover {
	background-color: #005B59;
	border-color    : #005B59;
}

/* === Typography === */
h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: 25px;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	font-weight: inherit;
}

h1 {
	font-size : 26px;
	color     : #40aae6;
	margin-top: 0;
}

h2 {
	font-size : 20px;
	color     : #2a333b;
	margin-top: 0;
}

h3 {
	font-size  : 18px;
	color      : #59585b;
	margin-top : 0;
	font-weight: 600;
}

h4 {
	font-size  : 16px;
	color      : #999;
	font-weight: normal;
}

h5 {
	font-size: 15px;
	color    : #999;
}

h6 {
	font-size: 14px;
	color    : #999;
}

/* === End === */

/* ========= About Page =========== */
.features {
	position: relative;
	z-index : 1;
}

.features .page-overlay {
	position: absolute;
	width   : 100%;
	height  : 100%;
	top     : 0;
	z-index : -1
}

.features .page-overlay {
	background: url(../img/overlay-pattern.png) repeat rgba(0, 0, 0, 0.4);
}

.features.parallax-area {
	padding : 0;
	position: relative;
	margin  : 50px 0 80px 0;
}

.features.parallax-area:before {
	position  : absolute;
	width     : 100%;
	height    : 100px;
	content   : "12";
	bottom    : 0;
	background: #FFF;
}

.mackbook {
	margin  : -40px 0 0 0;
	position: relative;
	padding : 0 0 0 0;
}

/* ========= End =========== */

/* ========= Service Section =========== */
.service-box {
	padding           : 20px;
	border-top        : #002665 solid 4px;
	-webkit-transition: all 0.15s linear;
	-moz-transition   : all 0.15s linear;
	-o-transition     : all 0.15s linear;
	transition        : all 0.15s linear;
	margin            : 0 0 40px 0;
}

.service-box .main-btn {
	margin-top: 10px;
}

.service-box:hover {
	border-top:#002665 solid 4px;
	border-bottom:#002665 solid 1px;
	border-left:#002665 solid 1px;
	border-right:#002665 solid 1px;
}

.service-box .icons {
	display       : table;
	margin-bottom : 20px;
	text-align    : right;
	width         : 100%;
	position      : relative;
	vertical-align: middle;
}

.service-icon {
	display       : table-cell;
	width         : 60px;
	text-align    : left;
	position      : relative;
	vertical-align: middle;
}

.service-box .icons i {
	width      : 60px;
	height     : 60px;
	line-height: 60px;
	background-color:#002665;
	color:#FFFFFF;

	text-align: center;
	border    : 1px solid #dddddd;
	font-size : 24px;

	border-radius        : 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius   : 50%;

	-webkit-transition: all 0.15s linear;
	-moz-transition   : all 0.15s linear;
	-o-transition     : all 0.15s linear;
	transition        : all 0.15s linear;
}

.service-box:hover .icons i {
	background: #008e8b;
	color     : #FFF;
	border    : 1px solid #008D8A;
}

.service-box .icons h3 {
	padding       : 0 20px;
	display       : table-cell;
	vertical-align: middle;
}

.client-box {
	/*background:#f7f8fa;*/
	background-color:#FFF;
	text-align:center;
	padding:15px;
	margin:0 0x;
	border:1px solid #dddddd;
}

/* ========= End =========== */

/* ========= Contact Section =========== */
.info-icon {
	display       : table-cell;
	vertical-align: middle;
	border-right  : 1px solid #dddddd;
	width: 40px;
	font-size     : 36px;
	color         : #008D8A;
	font-weight   : 400;
}

.info-text {
	color         : #59585b;
	display       : table-cell;
	vertical-align: middle;
	padding       : 10px 20px;
}

.info-text h3 {
	margin     : 0 0 8px 0;
	font-size  : 18px;
	font-weight: 600;
}	

#map-farma {
	height: 400px;
	margin: 40px 0 0 0;
}

/* ========= End =========== */

/* ========= Footer Section =========== */
footer {
	background: #333;
	padding   : 15px 0;
}

/* ========= End =========== */

/* ========= Back To Top =========== */
#back-top {
	bottom  : 20px;
	position: fixed;
	right   : 20px;
	z-index : 10000;
}

#back-top a {
	height     : 40px;
	line-height: 40px;
	width      : 40px;
	display    : block;
	color      : #FFF;
	text-align : center;
	background : #008D8A;
	font-size  : 16px;
	font-weight: 500;
}

#back-top a:hover {
	color     : #008D8A;
	background: #59585b;
}

.arrow {
	fill: #ffffff;
}

/* ========= End =========== */

/* INTRO INFORMACIÓN */
.intro-banner {
	margin-top: -180px;
	text-align: left;
}

.intro-panel {
	padding: 0 2px 0 2px;
}

.intro-info {
	min-height      : 400px;
	background-color: #005B59;
	border-top      : #FFFFFF solid 4px;
	color           : #FFFFFF;
}

.intro-header {
	padding: 10px 0 0 10px;
}

.intro-info h2 {
	color  : #FFFFFF;
	margin : 0;
	padding: 0;
}

.intro-info .list-group {
	margin : 15px 0 0 0;
	padding: 0;
}

.intro-info .list-group-item {
	border    : none;
	background: none;
	color: #f5f5f5;
	padding-top: .4rem;
	padding-bottom: .4rem;
}

.intro-info .list-group-item:first-child,
.intro-info .list-group-item:last-child {
	border-radius: 0;
}

.intro-info a.list-group-item:focus,
.intro-info a.list-group-item:hover {
	background-color: #008e8b;
}

.intro-info a.list-group-item p {
	overflow     : hidden;
	white-space  : nowrap;
	text-overflow: ellipsis;
}



a.intro-more {
	display         : block;
	line-height     : 40px;
	padding         : 0 10px;
	background-color: #005B59;
	color           : #FFFFFF;
	border-top      : #dddddd solid 1px;
}

a.intro-more:hover {
	background-color: #007472;
}

.intro-servicios {
	min-height      : 455px;
	background-color: rgba(255, 255, 255, 0.7);
	border-top      : #005B59 solid 4px;
	color           : #005B59;
	padding         : 15px;
}

.intro-servicios h2 {
	color: #005B59;
}

.intro-servicios .nav-pills>li>a {
	color           : #fff;
	background-color: #005B59;
	padding         : 15px 10px;
	margin          : 3px 0;
}

.intro-servicios .nav-pills>li>a:focus,
.intro-servicios .nav-pills>li>a:hover {
	background-color: #008e8b;
	font-size       : 17px;
}

.intro-info .fa-6 {
	font-size  : 4em !important;
	line-height: 1.4em;
}

.intro-servicios .fa-6 {
	font-size: 5em !important;
}

.intro-servicios .nav .fa {
	font-size: 2em !important;
}

/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

/* Carousel base class */
.carousel {
	box-shadow: 0 2px 6px rgb(0 0 0 / 20%);
}

/* Since positioning the image, we need to help out the caption */
.carousel-caption {
	bottom : 14rem;
	z-index: 10;
}

/* Declare heights because of positioning of img element */
.carousel-item {
	height: 600px;
}

.carousel-item>img {
	position  : absolute;
	top       : 0;
	left      : 0;
	min-height: 600px;
}

.img-slider {
	background-color   : rgba(0, 0, 0, 0);
	background-repeat  : no-repeat;
	background-size    : cover;
	background-position: center center;
	width              : 100%;
	height             : 600px;
}

.img-slider>div {
	width     : 100%;
	height    : 100%;
	background: url(../img/overlay-pattern.png) repeat rgba(0, 0, 0, 0.5);
}

.receta {
	background-image: url('../img/sliders/banner_recetario.jpg');
}

.cuidados {
	background-image: url('../img/sliders/coronavirusCuidados.jpg');
}

.sintomas {
	background-image: url('../img/sliders/coronavirusSintomas.jpg');
}

.administracion {
	background-image: url('../img/sliders/introLanding.jpg');
}

.auditoria {
	background-image: url('../img/sliders/introLanding02.jpg');
}



/* RESPONSIVE CSS
  -------------------------------------------------- */

@media(min-width:767px) {
    .navbar {
        padding: 20px 0;
        -webkit-transition: background .5s ease-in-out,padding .5s ease-in-out;
        -moz-transition: background .5s ease-in-out,padding .5s ease-in-out;
        transition: background .5s ease-in-out,padding .5s ease-in-out;
    }

    .top-nav-collapse {
        padding: 0;
    }
}

@media (min-width: 40em) {

	/* Bump up size of carousel content */
	.carousel-caption p {
		margin-bottom: 1.25rem;
		font-size    : 1.25rem;
		line-height  : 1.4;
	}

	.featurette-heading {
		font-size: 50px;
	}
}

@media (min-width: 62em) {
	.featurette-heading {
		margin-top: 7rem;
	}
}

/* carusel */
.carousel-caption p {
	font-size     : 30px;
	font-weight   : 500;
	color         : #f5f5f5;
	/*text-transform: uppercase;*/
	/*border-top  :#008D8A solid 3px;*/
	/*width       :170px;*/
	text-align    : center;
}

.carousel-caption h1 {
	font-size  : 45px;
	font-weight: 700;
	color      : #FFFFFF;
	line-height: 1.1em;
	text-align : center;
}


/* subpaginas */
.subpageFarma {
	background-position: center center;
	background-repeat  : no-repeat;
	background-size    : cover;
	height             : 400px;
	box-shadow         : 0 2px 6px rgba(0, 0, 0, 0.2);
}

.intro-subpage {
	margin-top: -250px;
	text-align: left;
}

.intro-subpage .main-heading {
	border-left-color: #FFFFFF;
}

.intro-subpage .main-heading h1,
.intro-subpage .main-heading h4 {
	color: #FFFFFF;
}

/**** PAGINA NOTICIAS *****/

.intro-section-subpage {
	min-height      : calc(100vh - 85px);
	background-color: #dddddd;
	overflow        : hidden;
	padding-bottom  : 60px;
}

.intro-subpage {
	margin-top: -250px;
	text-align: left;
}

.content {
	background-color: #fff;
	box-shadow      : 0 2px 5px 0 rgba(0, 0, 0, 0.26);
	border-radius   : 2px;
	overflow        : hidden;
	padding         : 50px 0;
}

.list-public {
	display        : block;
	min-height     : 96px;
	text-decoration: none;
	border-bottom  : 1px solid #e0e0e0;
	border-top     : 1px solid #e0e0e0;
	margin-top     : -1px;
	padding-left: 15px;
	padding-right: 15px;
}

.list-public:hover {
	background-color: #00BCD4;
}

.list-public .title {
	color         : rgba(0, 0, 0, 0.87);
	font-size     : 20px;
	font-weight   : 400;
	overflow      : hidden;
	padding-top   : 36px;
	padding-bottom: 16px;
	text-overflow : ellipsis;
	white-space   : nowrap;
}

.list-public .date {
	color         : rgba(0, 0, 0, 0.65);
	font-size     : 20px;
	font-weight   : 400;
	overflow      : hidden;
	padding-top   : 36px;
	padding-bottom: 16px;
	text-overflow : ellipsis;
}

/**** PAGINA FAQ *****/
.faqFarma .panel-group .panel {
	margin-bottom: 0;
	border-radius: 0;
}

.faqFarma .panel-default {
	border: none;
}

.faqFarma .panel-group .panel+.panel {
	margin-top: 0;
}

.faqFarma .panel-group .panel-heading+.panel-collapse>.list-group,
.panel-group .panel-heading+.panel-collapse>.panel-body {
	border-top: none;
}

.faqFarma .panel-default>.panel-heading {
	box-sizing   : border-box;
	border-top   : 1px solid #e0e0e0;
	border-bottom: 1px solid #e0e0e0;
	border-radius: 0;
	display      : inline-block;
	font-size    : 14px;
	font-weight  : normal;
	line-height  : 20px;
	margin-top   : -1px;
	outline      : none;
	font-size    : 15px;
	line-height  : 24px;
	padding      : 16px 50px 16px 16px;
	position     : relative;
	width        : 100%;
	background   : none;
}

.faqFarma h3 {
	background: #002665;
	padding   : 10px;
	color     : #80DEEA;
}

/**** PAGINA LINKS *****/
.list-link {
	display         : block;
	min-height      : 96px;
	text-decoration : none;
	margin-top      : 3px;
	margin-left     : 3px;
	border-left     : #002665 solid 5px;
	background-color: #f5f5f5;
	padding-left: 15px;
}

.list-link:hover {
	background-color: #00BCD4;
}

.list-link .title {
	color         : rgba(0, 0, 0, 0.87);
	font-size     : 20px;
	font-weight   : 400;
	overflow      : hidden;
	padding-top   : 36px;
	padding-bottom: 16px;
	text-overflow : ellipsis;
	white-space   : nowrap;
}

.list-link .linkArrow {
	color        : #BDBDBD;
	font-size    : 75px;
	line-height  : 96px;
	font-weight  : 400;
	overflow     : hidden;
	text-overflow: ellipsis;
	white-space  : nowrap;
	text-align   : center;
}

.list-link:hover .linkArrow {
	color: #f5f5f5;
}

/**** PAGINA CLIENTES *****/
.list-cliente {
	display         : block;
	min-height      : 55px;
	text-decoration : none;
	margin-top      : 3px;
	margin-left     : 3px;
	border-left     : #002665 solid 5px;
	background-color: #f5f5f5;
	padding-left: 30px;
}

.list-cliente:hover {
	background-color: #00BCD4;
}

.list-cliente .title {
	color         : rgba(0, 0, 0, 0.87);
	font-size     : 20px;
	font-weight   : 400;
	overflow      : hidden;
	padding: 15px 0 15px 0;
	text-overflow : ellipsis;
	white-space   : nowrap;
}

.list-cliente .linkArrow {
	color        : #BDBDBD;
	font-size    : 50px;
	line-height  : 50px;
	font-weight  : 400;
	overflow     : hidden;
	text-overflow: ellipsis;
	white-space  : nowrap;
	text-align   : center;
}

.list-cliente:hover .linkArrow {
	color: #f5f5f5;
}

.subtitulo h1 {
	margin-top : 0;
	font-size  : 30px;
	font-weight: 600;
	color      : #002665;
}

.subtitulo-heading {
	padding    : 0px 0 0 10px;
	border-left: 2px solid #59585b;
	position   : relative;
	margin     : 30px 0 0 0;
}

.af_panelFormLayout {
	background-color: #FFF;
}

#appBarHome {
	padding-left: 5%;
}

.navbar-default .navbar-toggle .icon-bar {
	background-color: #888 !important;
}

.row-logo {
	min-height: 150px;
}


.COMUNICADO-IMPORTANTE {
	margin-top: 20px;		
	font-size: 25px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1;
	letter-spacing: normal;
	text-align: center;
	color: black;
  }


  .link-nota {
	text-decoration: underline;
	height: 22px;
	font-family: OpenSans;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.63;
	letter-spacing: normal;
	text-align: left;
	color: #40a19d;
  }

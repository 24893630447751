/* ========= Parallax Style =========== */
.parallax-area {
	background-position: 50% 0;
	background-repeat  : no-repeat;
	position           : relative;
	padding            : 100px 0;
	margin             : 50px 0;
}

.parallax-image {
	background-color     : #FFF;
	color                : #FFF;
	background-attachment: fixed;
	background-size      : cover;
}

.features.parallax-image {
	background-image: url(../img/medications-257336_1920.jpg);
}

.contact-bg.parallax-area {
	margin: 70px 0 0 0;
}

/* ========= End =========== */

.owl-carousel .owl-item img {
	width  : inherit;
	display: inherit;
}

/* ========= Features Box =========== */
.features-box {
	margin: 0 0 30px 0;
}

.icon-box {
	width      : 40px;
	height     : 40px;
	line-height: 40px !important;
	text-align : center;
	float      : left;
	background : #008D8A;
	font-size  : 18px;
	color      : #FFF;
}

.features-text {
	overflow: hidden;
	padding : 0 0 0 20px;
}

.features-text h4 {
	color      : #59585b;
	font-size  : 16px;
	margin     : 0 0 10px 0;
	font-weight: 500;
}

/* ========= End =========== */

/* ========= Contact Section =========== */
#map-holder {
	margin    : 0;
	background: #FFF;
	padding   : 0;
	height    : 400px;
}

#map_extended {
	height     : 100%;
	left       : 0;
	line-height: inherit;
	position   : relative;
	top        : 0;
	width      : 100%;
}

#map_extended img {
	max-width: inherit;
}

#map_extended .gm-style div {
	font: 13px/20px 'Raleway', 'Lucida Sans Unicode', 'Lucida Grande', sans-serif;
}

#map_extended .gmap_marker address {
	width: 300px;
}

#map_extended .gmap_marker address>div {
	width  : 100%;
	display: inline-block;
}

#map_extended .gmap_marker address div div {
	float: right;
	width: 70%;
}

#map_extended .gmap_marker address div div:first-child {
	width: 30%;
	float: left;
}

/* ========= Button Style =========== */
.main-btn {
	color  : #59585b;
	border : 1px solid #59585b;
	padding: 5px 20px;
	display: inline-block;

	border-radius        : 50px;
	-webkit-border-radius: 50px;
	-moz-border-radius   : 50px;

}

.main-btn:hover {
	background: #f15b5a;
	border    : 1px solid #f15b5a;
	color     : #FFF;

}

/* ========= End =========== */